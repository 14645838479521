// 
// Youtube modal with thumbnail toggler
// 

.youtube-modal-toggler {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &__thumb {
    filter: brightness(0.8);
    transition: $transition-base;
  }
  &__play {
    position: absolute;
    height: 5rem;    
    width: 5rem !important;
    color: $primary;
    transition: $transition-base;
  }
  &:hover {
    .youtube-modal-toggler__thumb {
      filter: brightness(1);
    }
    .youtube-modal-toggler__play {
      color: adjust-color($color: $primary, $lightness: 10%);
    }
  }
}

.youtube-modal {
  .close {
    position: absolute;
    top: -3rem;
    right: -3rem;
    -webkit-appearance: none;
    background-color: transparent;
    color: $light;
    border: 0;
    @include font-size(2rem);
  }
}