//
// Cards
//

.card {
  background-color: $white;
  padding: 2.5rem 1.75rem;
  border-radius: 5px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
  text-align: center;
  justify-items: center;
  display: grid;
  grid-gap: $headings-margin-bottom * 1.5;
  grid-template-columns: 1fr;
  grid-template-rows: 125px fit-content(100%) auto;
  @include media-breakpoint-down(md) {
    padding: 1.25rem 0.75rem;
    grid-template-rows: auto;
  }
  * {
    margin-bottom: 0;
  }
  &__image {
    max-width: 125px;
    max-height: 125px;
    @include media-breakpoint-down(md) {
      max-width: 80px;
      max-height: 80px;
    }
  }
  &__text {
      @include font-size($font-size-sm);
  }
}

.help-card {
  background-color: $primary;
  color: $light;
  text-decoration: none;
  width: 120px;
  padding: 1rem;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.33);
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: $transition-base;
  &__image {
    max-height: 50px;
    margin-bottom: .5rem;
  }
  &__title {
    @include font-size($font-size-base);
    margin-top: auto;
    margin-bottom: 0;
  }
  &:hover {
    color: $light;
    text-decoration: none;
    transform: scale(1.1);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.66);
  }
}

.featured-card {
  background-color: $primary;
  color: $light;
  text-decoration: none;
  padding: 2rem;
  border-radius: 1rem;
  text-align: center;
  &__label.label-heading {
    color: $light;
    margin-bottom: .5rem;
  }
  &__title {
    margin-top: auto;
    padding-bottom: $headings-margin-bottom;
    margin-bottom: $headings-margin-bottom;
    line-height: $headings-line-height;
    @include font-size($h2-font-size);
    font-weight: $headings-font-weight;
    max-width: 400px;
  }
  &__products {
    display: grid;
    grid-template-columns: repeat(auto-fill, 45%);
    justify-content: center;
    column-gap: 1rem;
    row-gap: 1rem;
  }
  &__product {
    padding: .75rem;
    border: solid 1px $light;
    border-radius: .5rem;
    color: $light;
    text-decoration: none;
    transition: $transition-base;
    &-icon {
      background-color: $light;
      width: 75px;
      height: 75px;
      margin: 0 auto .5rem auto;
      transition: $transition-base;
    }
    &-title {
      @include font-size($h5-font-size);
      font-weight: $font-weight-medium;
    }
    &-subtitle {
      @include font-size($h5-font-size);
      font-weight: $font-weight-light;
    }
    &:hover {
      background-color: $light;
      color: $link-color;
      text-decoration: none;
      .featured-card__product-icon {
        background-color: $primary;
      }
    }
  }
  &__video {
    &-thumb {

    }
    &-play {

    }
  }
}