//
// Post preview
//

.post-preview {
  display: grid;
  @include font-size($font-size-base * 0.9);
  grid-gap: .25rem;
  grid-template-columns: 1fr;
  grid-template-rows: fit-content(720px) repeat(2,fit-content(3rem)) auto fit-content(3rem);
  grid-template-areas:
    'thumbnail     '
    'date          '
    'title         '
    'excerpt       '
    'readmore      ';
  &__thumbnail {
    grid-area: thumbnail;
    margin-bottom: $headings-margin-bottom;
    > img {
      display: block;
      border-radius: $border-radius;
      object-fit: cover;
    }
  }
  &__date-published {
    grid-area: date;
    display: grid;
    grid-template-columns: repeat(2, max-content);
    grid-gap: 0.5rem;
    align-items: center;
  }
  &__title {
    grid-area: title;
    @include font-size($h4-font-size);
    font-weight: $font-weight-medium;
    > a {
      text-decoration: none;
    }
  }
  &__excerpt {
    grid-area: excerpt;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;  
    overflow: hidden;
  }
  &__readmore {
    grid-area: readmore;
  }
}
