//
// Actionbar
//

.actionbar {
  border-bottom: solid $border-width $border-color;
  @include media-breakpoint-down(md) {
    @include font-size($font-size-sm);
  }
  &__container {
    display: flex;
    justify-content: space-between;
  }
  &__contact {
    @include media-breakpoint-down(md) {
      .nav-link {
        padding: $nav-link-padding-y $nav-link-padding-x / 2;
      }
    }
  }
  &__social {
    .nav-link {
      padding: $nav-link-padding-y $nav-link-padding-x / 2;
    }
    @include media-breakpoint-down(md) {
      display: none;
    }
  }
}
