// 
// Dropdowns
//

.dropdown-item {
  white-space: normal;
}

a.dropdown-item {
  text-decoration: none;
}