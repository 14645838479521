// 
// Responsive images
// 

.figure-caption {
  text-align: center;
}

.permalink-icon {
  max-width: $h3-font-size;
  max-height: $h3-font-size;
  padding: .15em;
  vertical-align: bottom;
}