// 
// Blog layout
//

.blog-intro {
  position: relative;
  background-color: $primary;
	background-image: linear-gradient(to right, darken($primary, 10%), lighten($primary, 10%));  
	padding: 2rem 0;
  color: $light;
  &__container {
    position: relative;
    z-index: 1;
  }
  &__title {
    margin-bottom: 0;
  }
}

.blog-latest {
  padding-top: 3rem; 
  padding-bottom: 3rem;
  &__grid {
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: 1fr 1fr 1fr;
    @include media-breakpoint-down(sm) {
      grid-template-columns: 1fr;
    }
  }
}

.blog-navigation {
  padding-top: 1rem; 
  padding-bottom: 2rem;
}