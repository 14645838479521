//
// Typography
//

.label-heading {
  color: $gray;
  text-transform: uppercase;
  letter-spacing: 2px;
  @include font-size($h6-font-size);
  font-weight: $headings-font-weight;
}