//
// Buttons
//

@use 'sass:color';

.btn {
  font-weight: $font-weight-medium;
  text-decoration: none;
  text-transform: uppercase;
  &-light,
  &-white {
    color: $primary;
  }
}

.btn-pulse {
  @include button-variant($success, $success);
  animation: btn-shadow-pulse 1s infinite;
}

@keyframes btn-shadow-pulse {
  0% {
    box-shadow: 0 0 0 0px color.adjust($success, $alpha: -0.7);
  }

  100% {
    box-shadow: 0 0 0 15px color.adjust($success, $alpha: -1);
  }
}
