// 
// Forms
//

.form-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.form-check-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
}